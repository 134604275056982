<template>

  <section v-if="visible" class="container p-0">
    <div class="row">
      <div class="col-7 p-4 border-right auto-layout">
        <div v-if="showContractNext" class="row">
          <p v-show="!facOnly"><b>{{ currentContractStep }}</b>/{{ maxContractStep + ' ' + $t('employee.fillcontract') }}</p>
          <h1>{{ $t('employee.step_contract_' + currentContractStep) }}</h1>
        </div>
        <div v-show="currentContractStep == 1" id="step-1">
          <div class="row" v-if="contractData.effective_date !== undefined">
            <label for="startdate">{{ $t('form.effective_date') }}</label>
            <input type="date" id="effdate" v-model="contractData.effective_date" @change="isValid" />
          </div>
          <div class="row mb-3" v-if="!salaryOnly">
            <label>{{ $t('form.edit') }}</label>
            <div class="ps-1">
              <input type="checkbox" v-model="editMoreData" id="article-0">
              <label for="article-0" class="fw-bold d-inline">Meer aanpassen</label>
              <template v-if="editMoreData">
                <br> <input type="checkbox" v-model="editSalary" id="article-1">
                <label for="article-1" class="fw-bold d-inline">salaris</label>
                <br> <input type="checkbox" v-model="editSchedule" id="article-2">
                <label for="article-2" class="fw-bold d-inline">rooster</label>
                <br> <input type="checkbox" v-model="editFunction" id="article-3">
                <label for="article-3" class="fw-bold d-inline">functie</label>
                <br> <input type="checkbox" v-model="editDepartment" id="article-4">
                <label for="article-4" class="fw-bold d-inline">afdeling</label>
                <br> <input type="checkbox" v-model="editCompensations" id="article-5">
                <label for="article-5" class="fw-bold d-inline">overige vergoedingen</label>
              </template>
            </div>
          </div>
          <div class="row" v-if="!salaryOnly && (!professionEdit || (professionEdit && editFunction))">
            <label for="job-title">{{ $t('employee.job_title') }}</label>
            <select id="job-title" class="optional" v-model="contractData.job_function.debtor_function_id" @change="isValid">
              <option :value="null" disabled>{{ $t('form.select') }}</option>
              <option v-for="func in jobFunctions" :key="func.id" :value="func.id" @click="contractData.job_function.name = func.name">{{ func.name }}</option>
            </select>
          </div>
          <div class="row" v-if="!salaryOnly && !professionEdit">
            <label for="reference_number">{{ $t('employee.reference_number') }}</label>
            <input type="text" id="reference_number" class="optional" v-model="contractData.job_function.reference_number" />
          </div>
          <div class="row" v-if="!salaryOnly && (!professionEdit || (professionEdit && editDepartment))">
            <label for="department">{{ $t('employee.department') }}</label>
            <select id="department" class="optional" v-model="contractData.job_function.debtor_department_id" @change="isValid">
              <option :value="null" disabled>{{ $t('form.select') }}</option>
              <option v-for="dep in jobDepartments" :key="dep.id" :value="dep.id" @click="contractData.job_function.department = dep.name">{{ dep.name }}</option>
            </select>
          </div>
          <div class="row" v-if="!salaryOnly">
            <label>{{ $t('employee.contract_type') }}</label>
            <fieldset class="btn-group" :data-field="contractData.job_function.fixed_term">
              <input id='ct1' type="radio" name="contract_type" class="btn-check" :value="1" v-model="contractData.job_function.fixed_term" @change="isValid" />
              <label for="ct1" class="btn btn-sm">{{$t('employee.contract_limited')}}</label>
              <input id='ct2' type="radio" name="contract_type" class="btn-check" :value="0" v-model="contractData.job_function.fixed_term" @change="isValid" />
              <label for="ct2" class="btn btn-sm">{{$t('employee.contract_unlimited')}}</label>
            </fieldset>
          </div>
          <div class="row" v-if="!salaryOnly && (!professionEdit || (professionEdit && editSchedule))">
            <label>{{ $t('employee.probation') }}</label>
            <fieldset class="btn-group" :data-field="contractData.job_function.probation">
              <input id='probation1' type="radio" name="probation_type" class="btn-check" :value="1" v-model="contractData.job_function.probation" @change="isValid" />
              <label for="probation1" class="btn btn-sm">{{$t('employee.probation_1')}}</label>
              <input id='probation2' type="radio" name="probation_type" class="btn-check" :value="0" v-model="contractData.job_function.probation" @change="isValid" />
              <label for="probation2" class="btn btn-sm">{{$t('employee.probation_2')}}</label>
            </fieldset>
          </div>
          <div class="row" v-if="!salaryOnly && (!professionEdit || (professionEdit && editSchedule))">
            <label>{{ $t('employee.employment') }}</label>
            <fieldset class="btn-group" :data-field="contractData.job_function.employment_type">
              <input id='employment1' type="radio" name="employment" class="btn-check" value="fulltime" v-model="contractData.job_function.employment_type" @change="isValid" />
              <label for="employment1" class="btn btn-sm">{{$t('employee.employment_type_fulltime')}}</label>
              <input id='employment2' type="radio" name="employment" class="btn-check" value="hourly" v-model="contractData.job_function.employment_type" @change="isValid" />
              <label for="employment2" class="btn btn-sm">{{$t('employee.employment_type_hourly')}}</label>
              <input id='employment3' type="radio" name="employment" class="btn-check" value="internship" v-model="contractData.job_function.employment_type" @change="isValid" />
              <label for="employment3" class="btn btn-sm">{{$t('employee.employment_type_internship')}}</label>
            </fieldset>
          </div>
          <div class="row" v-if="!salaryOnly">
            <label for="startdate">{{ $t('form.start_date') }}</label>
            <input type="date" id="startdate" v-model="contractData.job_function.start_date" @change="isValid" />
          </div>
          <div class="row" v-if="!salaryOnly && contractData.job_function.fixed_term">
            <label for="enddate">{{ $t('form.end_date') }}</label>
            <input type="date" id="enddate" v-model="contractData.job_function.fixed_term_expiration_date" @change="isValid" />
          </div>
          <div class="row" v-if="!salaryOnly && showSkilledWorker">
            <label>{{ $t('employee.profession') }}</label>
            <fieldset class="btn-group optional" :data-field="contractData.job_function.skilled_worker">
              <input id='profession1' type="radio" name="profession" class="btn-check" :value="1" v-model="contractData.job_function.skilled_worker" />
              <label for="profession1" class="btn btn-sm">{{$t('employee.profession_1')}}</label>
              <input id='profession2' type="radio" name="profession" class="btn-check" :value="0" v-model="contractData.job_function.skilled_worker" />
              <label for="profession2" class="btn btn-sm">{{$t('employee.profession_2')}}</label>
              <input id='profession3' type="radio" name="profession" class="btn-check" :value="null" v-model="contractData.job_function.skilled_worker" />
              <label for="profession3" class="btn btn-sm">{{$t('employee.profession_3')}}</label>
            </fieldset>
          </div>
          <template v-if="contractData.job_function.employment_type == 'fulltime' || contractData.job_function.employment_type == 'parttime'">
            <div class="row" v-if="!salaryOnly && (!professionEdit || (professionEdit && editSchedule))">
              <label>{{ $t('employee.roster_1') }}</label>
              <div>
                <div class="row employee-schedule">
                  <small class="col"></small>
                  <div class="col" v-for="weekday in weekdays" :key="weekday">{{weekday}}</div>
                </div>
                <div class="row employee-schedule">
                  <small class="col">Week 1</small>
                  <input type="number" class="col tiny-input" v-for="n in 5" :key="n" v-model="schedule[n-1]" @input="calculateTotalHours" @keydown="preventNonNumbers" @change="isValid" />
                  <input type="number" class="col tiny-input optional" v-for="n in 2" :key="n" v-model="schedule[4+n]" @input="calculateTotalHours" @keydown="preventNonNumbers" />
                </div>
                <div class="row employee-schedule mt-1">
                  <small class="col">Week 2</small>
                  <input type="number" class="col tiny-input" v-for="n in 5" :key="n" v-model="schedule[6+n]" @input="calculateTotalHours" @keydown="preventNonNumbers" @change="isValid" />
                  <input type="number" class="col tiny-input optional" v-for="n in 2" :key="n" v-model="schedule[11+n]" @input="calculateTotalHours" @keydown="preventNonNumbers" />
                </div>
              </div>
            </div>
            <div class="row mt-2" v-if="!salaryOnly && (!professionEdit || (professionEdit && editSchedule))">
              <label>{{ $t('employee.hours') }}</label>
              <div type="number" class="optional-disabled">{{getHoursCleaned(contractData.job_function.hours)}}</div>
            </div>
          </template>
          <div class="row salaryclass" v-if="(!professionEdit || (professionEdit && editSalary))">
            <label for="salary">{{ $t('employee.salary_' + (contractData.job_function.hours	== 38 ? 'fulltime' : 'parttime')) }}</label>
            <input type="number" id="salary" v-model="contractData.job_function.salary" @change="isValid" />
          </div>

          <div class="row" v-if="!salaryOnly && (!professionEdit || (professionEdit && editCompensations))">
            <label>{{ $t('employee.compensation') }}</label>
            <fieldset class="btn-group" :data-field="contractData.job_function.additional_compensations">
              <input id='compensation1' type="radio" name="compensation" class="btn-check" :value="1" v-model="contractData.job_function.additional_compensations" @change="isValid" />
              <label for="compensation1" class="btn btn-sm">{{$t('form.yes')}}</label>
              <input id='compensation2' type="radio" name="compensation" class="btn-check" :value="0" v-model="contractData.job_function.additional_compensations" @change="isValid" />
              <label for="compensation2" class="btn btn-sm">{{$t('form.no')}}</label>
            </fieldset>
          </div>

          <template v-if="contractData.job_function.additional_compensations">
            <div class="auto-layout">
              <div class="row comp-travel" v-if="!salaryOnly && (!professionEdit || (professionEdit && editCompensations))">
                <label class="ps-0 pt-1">{{$t('employee.comp_travel')}}</label>
                <fieldset class="btn-group ps-0" :data-field="contractData.job_function.travel_compensation_type">
                  <input id='tc1' type="radio" name="tc_type" class="btn-check" value="none" v-model="contractData.job_function.travel_compensation_type" @change="isValid($event); contractData.job_function.travel_compensation = null" />
                  <label for="tc1" class="btn btn-sm">{{$t('employee.travel_compensation_type_1')}}</label>
                  <input id='tc2' type="radio" name="tc_type" class="btn-check" value="distance" v-model="contractData.job_function.travel_compensation_type" @change="isValid($event); contractData.job_function.travel_compensation = '0.23'" />
                  <label for="tc2" class="btn btn-sm">{{$t('employee.travel_compensation_type_2')}}</label>
                  <input id='tc3' type="radio" name="tc_type" class="btn-check" value="monthly" v-model="contractData.job_function.travel_compensation_type" @change="isValid($event); contractData.job_function.travel_compensation = null" />
                  <label for="tc3" class="btn btn-sm">{{$t('employee.travel_compensation_type_3')}}</label>
                  <input id='tc4' type="radio" name="tc_type" class="btn-check" value="calculate" v-model="contractData.job_function.travel_compensation_type" @change="isValid($event); contractData.job_function.travel_compensation = null" />
                  <label for="tc4" class="btn btn-sm">{{$t('employee.travel_compensation_type_4')}}</label>
                </fieldset>
              </div>
            </div>
            <div class="auto-layout-3">
              <div class="row" v-if="!salaryOnly && (!professionEdit || (professionEdit && editCompensations)) && contractData.job_function.travel_compensation_type == 'monthly'">
                <label></label>
                <div class="input-group">
                  <span>€</span> <input id="travelcomp-1" type="number" class="optional" v-model="contractData.job_function.travel_compensation" @change="isValid" />
                </div>
                <span>{{ $t('employee.per_month') }}</span>
              </div>
              <template v-else-if="contractData.job_function.travel_compensation_type == 'calculate'">
                <div class="row" v-if="!salaryOnly">
                  <label class="pe-0">{{$t('employee.travel_compensation_days')}}</label>
                  <div> <input id="travelcomp-2" type="number" v-model="contractData.job_function.travel_compensation_days" @change="isValid($event); calcTravelCompensation()" /> </div>
                </div>
                <div class="row" v-if="!salaryOnly">
                  <label>{{$t('employee.travel_compensation_distance')}}</label>
                  <div class="input-group">
                    <input id="travelcomp-3" type="number" v-model="contractData.job_function.travel_compensation_distance" @change="isValid($event); calcTravelCompensation()" />
                    <span class="w-25 p-2">km</span>
                  </div>
                </div>
                <div class="ms-1 ps-2">
                  {{ $t('employee.travel_compensation_calculated') + (contractData.job_function.travel_compensation ? contractData.job_function.travel_compensation.toFixed(2) : '-') }}
                </div>
              </template>
              <br>
            </div>
            <div class="auto-layout">
              <div class="row comp-travel">
                <label class="ps-0 pt-1">{{$t('employee.work_home_compensation')}}</label>
                <fieldset class="btn-group ps-0" :data-field="workHomeType">
                  <input id='whc1' type="radio" name="whc_type" class="btn-check" value="none" v-model="workHomeType" @change="isValid($event); setWorkHomeCompensation(false)" />
                  <label for="whc1" class="btn btn-sm">{{$t('employee.travel_compensation_type_1')}}</label>
                  <input id='whc2' type="radio" name="whc_type" class="btn-check" value="monthly" v-model="workHomeType" @change="isValid($event); setWorkHomeCompensation(true)" />
                  <label for="whc2" class="btn btn-sm">{{$t('employee.work_home_compensation_type_1')}}</label>
                  <input id='whc3' type="radio" name="whc_type" class="btn-check" value="calculate" v-model="workHomeType" @change="isValid($event); setWorkHomeCompensation(true)" />
                  <label for="whc3" class="btn btn-sm">{{$t('employee.work_home_compensation_type_2')}}</label>
                </fieldset>
              </div>
            </div>
            <div class="auto-layout-3">
              <div class="row" v-if="!salaryOnly && workHomeType == 'monthly'">
                <label></label>
                <div class="input-group">
                  <span>€</span> <input id="wcomp-1" type="number" v-model="contractData.job_function.work_days_at_home_compensation" @change="isValid" />
                </div>
                <span>{{ $t('employee.per_month') }}</span>
              </div>
              <template v-else-if="workHomeType == 'calculate'">
                <div class="row">
                  <label class="pe-0">{{$t('employee.work_home_compensation_days')}}</label>
                  <div> <input id="wcomp-2" type="number" v-model="contractData.job_function.total_work_days_at_home" @change="isValid($event); calcWorkHomeCompensation()" /> </div>
                </div>
                <div class="ms-1 ps-2">
                  {{ $t('employee.work_home_compensation_calculated') + (contractData.job_function.work_days_at_home_compensation ? Number(contractData.job_function.work_days_at_home_compensation).toFixed(2) : '-') }}
                </div>
              </template>
              <br>
            </div>
            <div class="auto-layout-3">
              <div class="row">
                <label for="travelcomp-2">{{$t('employee.comp_expenses')}}</label>
                <div class="input-group">
                  <span>€</span> <input id="travelcomp-2" type="number" class="optional" v-model="contractData.job_function.expenses_compensation" @change="isValid" />
                </div>
                <span>{{ $t('employee.per_month') }}</span>
              </div>
            </div>
            <div class="auto-layout-3">
              <div class="row">
                <label for="travelcomp-3">{{$t('employee.comp_phone')}}</label>
                <div class="input-group">
                  <span>€</span> <input id="travelcomp-3" type="number" class="optional" v-model="contractData.job_function.phone_compensation" @change="isValid" />
                </div>
                <span>{{ $t('employee.per_month') }}</span>
              </div>
            </div>
          </template>
        </div>

        <div v-show="currentContractStep == 2" id="step-2">
          <div class="row">
            <label>{{ $t('employee.generate_contract') }}</label>
            <fieldset class="btn-group" :data-field="contractData.generate_contract">
              <input id='gen1' type="radio" name="generate_contract" class="btn-check" :value="1" v-model="contractData.generate_contract" @change="isValid" />
              <label for="gen1" class="btn btn-sm">{{$t('form.yes')}}</label>
              <input id='gen2' type="radio" name="generate_contract" class="btn-check" :value="0" v-model="contractData.generate_contract" @change="isValid" />
              <label for="gen2" class="btn btn-sm">{{$t('form.no')}}</label>
            </fieldset>
          </div>
          <template v-if="contractData.generate_contract === 1">
            <div class="row">
              <label for="template">{{ $t('employee.template') }}</label>
              <select id="template" v-model="contractData.template_id" @change="isValid">
                <option :value="null" disabled>{{ $t('form.select') }}</option>
                <option v-for="template in companyTemplates" :key="template.id" :value="template.id">{{ template.name }}</option>
              </select>
            </div>
            <div class="row" v-if="attachments.length > 0">
              <label>{{ $t('employee.attachments') }}</label>
              <div v-if="contractData.attachments.length > 0">
                <div v-for="field, index in contractData.attachments" :key="index" class="responsive-list">
                  <select v-model="contractData.attachments[index]" @change="isValid">
                    <option value="" disabled>{{ $t('form.select') }}</option>
                    <option v-for="attachment in companyAttachments" :key="attachment.id" :value="attachment.id">{{ attachment.name }}</option>
                  </select>
                  <button type="button" class="btn" @click="responsiveListControl(contractData.attachments, index); refreshContract()">
                    <i class="fa fa-close text-danger"></i>
                  </button>
                </div>
              </div>
              <div v-else>{{ $t('form.no_selected', [$t('navigation.attachments')]) }}</div>
            </div>
            <div class="row my-2" v-if="attachments.length > 0">
              <label></label>
              <button type="button" class="btn action-button" @click="responsiveListControl(contractData.attachments); refreshContract()"> {{ $t('employee.add_attachment') }} </button>
            </div>
            <div class="row">
              <label for="contractdate">{{ $t('employee.contract_date') }}</label>
              <input type="date" id="contractdate" v-model="contractData.generate_date" @change="isValid" />
            </div>
            <div class="row">
              <label for="contractplace">{{ $t('employee.contract_place') }}</label>
              <input type="text" id="contractplace" v-model="contractData.generate_place" @change="isValid" />
            </div>
          </template>
          <template v-else-if="contractData.generate_contract === 0">
            <UploadModule :model="(contractData.files ? contractData.files[0] : contractData.document)" title="Heb je al een contract van deze werknemer"
              description="Upload hem door hem in dat vak te slepen of <u>klik hier</u> om hem te zoeken" type="pdf" :owner="'contractFile4'" ref="contractFileUpload4"
              @addFile="(e) => addContractFile(e, 0)" @removeFile="removeContractFile" />
          </template>
        </div>

        <div v-show="currentContractStep == 3 && contractData.generate_contract === 1" id="step-3">
          <h5>{{ $t('employee.choose_articles') }}</h5>
          <div v-for="article, index in articles" :key="index" class="">
            <input type="checkbox" v-model="article.show" @change="setArticleIndexes" :id="'article-'+index">
            <label :for="'article-'+index" class="fw-bold d-inline">{{ article.prefix + ' ' + article.index + ' ' + article.name }}</label>
            <p v-show="article.show" v-html="article.text"></p>
          </div>
        </div>
        <div v-show="currentContractStep == 3 && contractData.generate_contract === 0" id="step-3b">
          <h5>{{ $t('employee.complete_1') }}</h5>
          <p> {{ $t('employee.complete_2') }} </p>
        </div>
        <div v-show="currentContractStep == 9" id="step-9">
          <h1>{{ $t('employee.mail_employee') }}</h1>
          <label for="to-line">{{ $t('employee.mail_to') }}</label>
          <input type="email" id="to-line" v-model="mail.email_to" @change="isValid" />
          <label for="subject">{{ $t('employee.mail_subject') }}</label>
          <input type="text" id="subject" v-model="mail.subject" @change="isValid" />
          <label for="message">{{ $t('form.message') }}</label>
          <textarea id="message" :rows="setTextAreaRows(mail.email_body)" v-model="mail.email_body"></textarea>
        </div>
        <div v-show="currentContractStep == 4" id="step-4">
          <h5>{{ $t('employee.review_contract') }}</h5>
          <div id="pell" class="pell" />
          <div v-if="contractData.attachments && contractData.attachments.length > 0">
            <h5 class="mt-4">{{ $tc('navigation.attachments', 2) }}</h5>
            <p v-for="(att, attIndex) in contractData.attachments" :key="attIndex" v-html="showAttachment(att)"></p>
          </div>
        </div>
        <div class="modal fade" id="facModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{ $t('employee.changes_mail_header') }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body"> {{ $t('employee.changes_mail_text') }} </div>
              <div class="modal-footer">
                <button type="button" class="btn secondary-button" data-bs-dismiss="modal" @click="saveSection(3, false)">{{ $t('form.no') }}</button>
                <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="setFACMail">{{ $t('form.yes') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-5 p-4">
        <div class="position-sticky" v-if="!facOnly">
          <h2>{{ $t('employee.fillcontract') }}</h2>
          <ol>
            <li :class="getListClass(1)">{{ $t('employee.step_contract_1') }}</li>
            <li :class="getListClass(2)" >{{ $t('employee.step_contract_2') }}</li>
            <li :class="getListClass(3)" v-if="contractData.generate_contract">{{ $t('employee.step_contract_3') }}</li>
          </ol>
        </div>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col-5 text-start">
        <button type="button" class="btn px-5 secondary-button" v-if="!isEditing || (currentContractStep != 1) || currentContractStep > 2" @click="onContractBack">{{ $t('form.back') }}</button>
      </div>
      <div class="col-7 text-end">
        <button type="button" class="btn px-5 btn-success" v-if="isEditing && facOnly && currentContractStep == 1" @click="setFACModal">{{ $t('form.save') }}</button>
        <button type="button" class="btn px-5 btn-success" v-else-if="facOnly && currentContractStep == 9" @click="sendMailChanges">{{ $t('form.send') }}</button>
        <button type="button" class="btn px-5 action-button" @click="setNext" v-else-if="showContractNext">{{ $t('form.next') }}</button>
        <template v-else>
          <button type="button" v-if="nawData.birth_date" class="btn px-5 action-button mx-3" @click="saveSection(3, 'download')"><i class="fa fa-download"></i>&ensp;{{ $t('overview.download_pdf') }}</button>
          <button type="button" class="btn px-5 btn-success" @click="saveSection(3, 'save')">{{ $t('employee.send_contract') }}</button>
        </template>
        <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#facModal">{{ $t('form.save') }}</button>
      </div>
    </div>
  </section>

</template>

<script>
  import UploadModule from '@/components/UploadModule'
  import contractService from '@/services/ContractService'
  import employeeService from '@/services/EmployeeService'
  import fileService from '@/services/FileService'
  import store from '@/store/user'
  import pellCSS from 'pell/dist/pell.min.css'
  export default {
    name: 'EditContract',
    props: ['visible', 'contractObject', 'nawObject', 'jobDepartments', 'jobFunctions', 'companyTemplates', 'attachments', 'companyAttachments', 'articles'],
    components: {
      UploadModule
    },
    computed: {
      nawData: { get() { return this.nawObject } },
      contractData: { get() { return this.contractObject } },
      showContractNext() {return this.contractObject.generate_contract ? this.currentContractStep <= this.maxContractStep : this.currentContractStep < 3 },
      showSkilledWorker() { return window.currentCustomer && window.currentCustomer.use_skilled_worker },
    },
    data() {
      return{
        salaryOnly: false,
        editMoreData: false,
        editSalary: false,
        editSchedule: false,
        editFunction: false,
        editDepartment: false,
        editCompensations: false,
        professionEdit: false,
        isEditing: false,
        facOnly: false,
        currentContractStep: 1,
        maxContractStep: 3,
        templateHeader: "",
        templateFooter: "",
        countries: [],
        nationalities: [],
        templates: [],
        documentTypes: [],
        companyData: {},
        isEmployeeFillingInNAW: false,
        shouldGenerateContract: true,
        sendDirectlyToNMBRS: false,
        workHomeType: "none",
        weekdays: ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'],
        mail: { email_to: "", subject: "", email_body: "" },
        schedule: ['8','8','8','8','8','0','0','8','8','8','8','8','0','0'],
        dict: {},
        pellEditor: null
      }
    },
    methods: {
      isValid(e){
        let el = e.target ? e.target : e;
        let shouldShowvalidity = true;
        let validityCheck = true;
        if(e.target) this.refreshContract();
        if(el.dataset.field){
          validityCheck = el.dataset.field;
        }
        else if(el.parentElement.dataset.field){
          el = el.parentElement;
          validityCheck = el.dataset.field;
        }
        else if(el.parentElement.parentElement.dataset.field){
          el = el.parentElement.parentElement;
          validityCheck = el.dataset.field;
        }
        else if(el.tagName === "SELECT" && el.value === this.$t('form.select')){
          validityCheck = false;
        }
        else{
          switch(el.type){
            case "email":
              //eslint-disable-next-line
              validityCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(el.value);
              break;
            case "tel":
              validityCheck = /^\+?\d{8,13}$/.test( el.value.replaceAll(" ", "").replaceAll("-", "") );
              break;
            case "number":
              validityCheck = /^[0-9,.]+$/.test(el.value);
              break;
            case "date":
              validityCheck = /^\d{4}-\d{2}-\d{2}$/.test(el.value);
              if(validityCheck && (el.hasAttribute('data-notinpast') || el.hasAttribute('data-notinfuture'))){
                const date = new Date(el.value);
                if(el.hasAttribute('data-notinpast'))
                  validityCheck = date > Date.now();
                else
                  validityCheck = date < Date.now();
              }
              break;
            case "file":
              break;
            default: {
              switch(el.id){
                case "postal-code":
                  validityCheck = /^\d{4}\s?[a-zA-Z]{2}$/.test(el.value);
                  break;
                default:
                  validityCheck = el.value;
                  break;
              }
            }
          }
        }

        if(shouldShowvalidity)
          this.showValidity(el, validityCheck);
        return validityCheck;
      },
      validate(){
        const step = this.currentContractStep;
        const els = document.querySelectorAll(`#step-${step} input:not(.optional):not([type="radio"]), #step-${step} select:not(.optional), #step-${step} fieldset:not(.optional)`);
        let isValid = true;
        els.forEach(el => {
          if(!this.isValid(el)){
            isValid = false;
          }
        });
        if(!isValid){
          alert(this.$t('errors.empty_fields'));
        }
        return isValid;
      },
      showAttachment(id){
        const attachment = this.attachments.find(att => att.id == id);
        return attachment ? attachment.contents.replaceAll("\n", "<br />") : "-";
      },
      setNext(){
        if(!this.validate()) return;
        if(this.currentContractStep == 1)
          this.$nextTick(this.$parent.initPell);
        if(this.currentContractStep == 2 && this.contractData.generate_contract)
          this.$parent.setArticles();
        else if(this.currentContractStep == 3 && this.contractData.generate_contract)
          this.$parent.setTemplate();
        this.currentContractStep++;

        window.scrollTo(0, 0);
      },
      sendContractData(nextFormStep){
        if(this.contractData.files){
          this.contractData.files = this.setFiles(this.contractData.files);
        }
        if(this.sendDirectlyToNMBRS){
          if(!nextFormStep){
            this.nawData.contract = this.contractData;
          }
          const data = {...this.contractData};
          data.employee = {...this.nawData};
          data.external_id = this.nawData.external_id;
          contractService.updateNMBRS(store.getters.getCurrentCustomerId, data).then(() => {
            window.location.href = window.location.origin + "/employees";
          }).catch(this.toastError);
        }
        else if(this.contractData.id){
          this.contractData.no_sign_request = nextFormStep == 'download';
          contractService.update(this.contractData).then((response) => {
            if(nextFormStep == 'download'){
              this.downloadPDF(this.contractData);
            }
            else if(nextFormStep == 'save' || !nextFormStep){
              window.location.href = window.location.origin + "/employees";
            }
            else{
              this.contractData.id = response.data.id;
              this.$parent.formSection = nextFormStep;
            }
          }).catch(this.toastError);
        }
        else{
          contractService.store(this.contractData).then((response) => {
            if(nextFormStep){
              this.contractData.id = response.data.id;
              this.nawData.id = response.data.employee_id;
              this.nawData.employee_status_id = 3;
            }
            employeeService.update(this.nawData).then(() => {
              if(nextFormStep){
                this.$parent.formSection = nextFormStep;
                this.nawData.employee_status_id = 4;
              }
              else 
                window.location.href = window.location.origin + "/employees";
            }).catch(this.toastError);
          }).catch(this.toastError);
        }
      },
      sendNAWData(nextFormStep){
        this.nawData.country_id = this.nawData.address.country_id;
        if(this.nawData.files){
          this.nawData.files = this.setFiles(this.nawData.files);
        }
        this.nawData.bsn_number = ("000000000" + this.nawData.bsn_number).slice(-9);
        if(this.sendDirectlyToNMBRS){
          if(!nextFormStep){
            this.nawData.contract = this.contractData;
          }
          const data = {...this.contractData};
          data.employee = {...this.nawData};
          data.external_id = this.nawData.external_id;
          contractService.updateNMBRS(store.getters.getCurrentCustomerId, data).then(() => {
            window.location.href = window.location.origin + "/employees";
          }).catch(this.toastError);
        }
        else if(this.nawData.id){
          employeeService.update(this.nawData).then(() => {
            if(nextFormStep) {
              if (this.contractData.generate_contract === 0 && nextFormStep == 3) {
                window.location.href = window.location.origin + "/employees";
              }
              this.$parent.formSection = nextFormStep;
            } else {
              window.location.href = window.location.origin + "/employees";
            }
          }).catch(this.toastError);
        }
        else{
          employeeService.store(this.nawData).then(() => {
            if(nextFormStep) {
              if (this.contractData.generate_contract === 0 && nextFormStep == 3) {
                window.location.href = window.location.origin + "/employees";
              }
              this.$parent.formSection = nextFormStep;
            } else {
              window.location.href = window.location.origin + "/employees";
            }
          }).catch(this.toastError);
        }
      },
      getListClass(step){
        const currentStep = this.currentContractStep;
        return step < currentStep ? "check" : (step == currentStep ? "active" : "inactive");
      },
      calculateTotalHours(e){
        let total = 0;
        this.schedule.forEach(hours => {
          const value = Number(hours);
          total += ((!hours || isNaN(value)) ? 0 : value);
        });
        total = total / 2;
        this.contractData.job_function.timetable = this.schedule.join(',');
        this.contractData.job_function.hours = total;
      },
      responsiveListControl(list, removeIndex = null){
        if(removeIndex == null)
          list.push('');
        else{
          list.splice(removeIndex, 1);
        }
      },
      saveSection(section, nextFormStep){
        if(!this.validate()) return;
        this.nawData.employee_status_id = 1;
        if(nextFormStep === 'download'){
          this.sendContractData(nextFormStep);
        }
        else{
          if(nextFormStep === 'save' || this.professionEdit) {
            this.sendContractData('save');
          }
          if(!this.professionEdit){
            this.sendNAWData(3);
          }
        }
      },
      onContractBack(){
        if(this.isEditing && this.currentContractStep == 9) this.currentContractStep = 1;
        else if(!this.isEditing && this.currentContractStep == 1) this.$parent.formSection = 2;
        else this.currentContractStep--;
      },
      setFACModal(e){
        if(this.validate()){
          e.target.nextElementSibling.click();
        }
      },
      setFACMail(){
        let changesString = "";
        const oldContractData = this.$parent.oldContractData;
        for(let field in oldContractData.job_function){
          const newVal = this.contractData.job_function[field];
          const oldVal = oldContractData.job_function[field];
          if(newVal != oldVal){
            if(field == 'employment_type'){
              changesString += "Verandering dienstverband: was eerst " + this.$t('employee.employment_type_' + oldVal) + ", veranderd naar " + this.$t('employee.employment_type_' + newVal) + ".\n";
            }
            if(field == 'salary'){
              const oldPer = oldContractData.job_function.employment_type == 'hourly' ? ' per uur' : ' per maand';
              const newPer = this.contractData.job_function.employment_type == 'hourly' ? ' per uur' : ' per maand';
              changesString += "Verandering salaris: was eerst €" + oldVal + oldPer + ", veranderd naar €" + newVal + newPer + ".\n";
            }
            if(field == 'name'){
              const newText = document.getElementById("job-title").selectedOptions[0].text;
              changesString += "Verandering functie: uw nieuwe functie is " + newText + ".\n";
            }
            if(field == 'department'){
              const newText = document.getElementById("department").selectedOptions[0].text;
              changesString += "Verandering afdeling: uw nieuwe afdeling is " + newText + ".\n";
            }
            if(field == 'timetable'){
              let newSchedule = "";
              const pretext = ["Ma", "Di", "Wo", "Do", "Vr", "Za", "Zo"];
              for(let index in this.schedule){
                if(index == 0) newSchedule += "--- Week 1 ---\n";
                if(index == 7) newSchedule += "--- Week 2 ---\n";
                newSchedule += (pretext[index % 7]  + " " + (this.schedule[index] ? this.schedule[index] : "0") + " uur\n");
              }
              changesString += "Nieuw rooster, uw nieuwe tijden zijn als volgt:\n" + newSchedule;
            }
          }
        }
        this.mail.email_to = this.contractData.employee.email;
        this.mail.email_body = "Beste " + this.contractData.employee.first_name + ", \n\n" + "Er zijn wijzigingen gemaakt in uw functie, uren en/of salaris.\nHieronder staan de wijzigingen op een rij:\n\n" + changesString + "\n" + "Met vriendelijke groet, IBEO";
        this.currentContractStep = 9;
      },
      sendMailChanges(){
        if(!this.validate()) return;
        contractService.mailChanges({
          "email_to": this.mail.email_to,
          "email_body": this.mail.email_body,
          "email_subject": this.mail.subject,
        }).then((response) => this.saveSection(3, false, true)).catch(this.toastError);
      },
      calcTravelCompensation(){
        this.contractData.job_function.travel_compensation = Math.ceil(1.6407 * Number(this.contractData.job_function.travel_compensation_distance) * 
            Number(this.contractData.job_function.travel_compensation_days) * 100) / 100; // (((214 * 0.23) / 5) * 2) / 12) = 1.6407
      },
      calcWorkHomeCompensation(){
        this.contractData.job_function.work_days_at_home_compensation = (214 * 2.15 * Number(this.contractData.job_function.total_work_days_at_home)) / 12;
      },
      setWorkHomeCompensation(hasComp){
        this.contractData.job_function.has_wage_tax_credit = hasComp;
        this.contractData.job_function.total_work_days_at_home = null;
        this.contractData.job_function.work_days_at_home_compensation = null;
      },
      showValidity(el, isValid, showCheckmark = false){
        if(isValid){
          if(showCheckmark)
            el.classList.add('is-valid');
          el.classList.remove('is-invalid');
        }
        else{
          if(showCheckmark)
            el.classList.remove('is-valid');
          el.classList.add('is-invalid');
        }
      },
      setTextAreaRows(text){
        const rows = text.replace(/[^\n]/g, '').length + 1;
        return rows < 4 ? 4 : rows;
      },
      refreshContract(){
        this.shouldGenerateContract = true;
      },
      setArticleIndexes(e){
        this.refreshContract();
        this.$parent.setArticleIndexes(e);
      },
      getCompany(){
        const name = store.getters.getCurrentCompanyName;
        if(!name){
          const el = document.getElementById('company-select');
          if(!el || el.selectedIndex === -1) return '-';
          return el.options[el.selectedIndex].innerHTML;
        }
        return name;
      },
      getHoursCleaned(value){
        const rvalue = value.toFixed(2).replace('.', ',').replace(',00', '');
        return rvalue[rvalue.length - 1] === '0' && rvalue.includes(',') ? rvalue.slice(0, -1): rvalue;
      },
      downloadPDF(item) {
        this.item = {...item};
        contractService.download(this.item.id).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            const companyName = this.getCompany().replaceAll(' ', '-');
            const pdfName = companyName + "_" + item.employee.first_name.replace(' ', '-') + '_' + item.employee.last_name.replace(' ', '-') + "_" + item.generate_date;
            link.setAttribute("download", pdfName + ".pdf");
            document.body.appendChild(link);
            link.click();
        }).catch(this.toastError);
      },
      addContractFile(file, index){
        if(!this.contractData.files) this.contractData.files = [null];
        fileService.store(file).then(response => {
          this.contractData.files[index] = response.data;
        });
      },
      removeContractFile(index){
        this.contractData.files[index] = null;
      },
      setFiles(fileData){
        let files = fileData;
        const size = files.length - 1;
        for(let i = size; i >= 0; i--){
          if(files[i] == null) files.splice(i, 1);
        }
        return files;
      }
    }
  }
</script>